
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Modal',
  components: {
    IconClose: () => import('@/assets/icons/close_icon.svg')
  },
  computed: {
    ...mapGetters({
      rulesOpen: 'layout/GGrulesOpen',
      rules: 'layout/GGRules'
    })
  },
  methods: {
    ...mapActions({
      toggleRulesOpen: 'layout/AArulesOpen'
    }),
    closeRules() {
      this.toggleRulesOpen(false)
    }
  }
}
